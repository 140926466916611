<template>
    <Auth :clear="true">
        <section class="page-error-view mt-card">
            <div class="logo-sesi">
                <ApplicationLogo />
            </div>

            <div class="not-found-card">
                <span class="icon-page-error mb-2">
                    <AlertCircleIcon />
                </span>

                <div class="text-center mb-1">
                    <h2 class="text-center">Página não encontrada</h2>
                    <p class="text-center">
                        Não foi possível carregar sua requisição.
                    </p>
                </div>

                <button @click="handleRedirect" type="button" class="btn button-page-error btn-block">
                    {{ getMessageButton }}
                </button>
            </div>
        </section>
    </Auth>
</template>

<script>
import Auth from "@/views/pages/authentication/BaseAuth/Auth";
import { AlertCircleIcon } from 'vue-feather-icons'
import ApplicationLogo from "@/views/components/custom/logo-sesi/ApplicationLogo";
import {isUserLoggedIn} from "@/auth/utils";

export default {
    components: {
        Auth,
        AlertCircleIcon,
        ApplicationLogo,
    },
    data() {
        return {
            sideImg: require('@/assets/images/pages/login-v2.svg'),
        }
    },

    methods: {
        handleRedirect() {
            let routeName = 'bem-vindo';

            if(!isUserLoggedIn()) {
                routeName = 'auth-login'
                this.$store.dispatch('sessions/logout');
            }

            this.$router.replace({ name: routeName });
        }
    },
    computed: {
        getMessageButton() {
            if(isUserLoggedIn) {
                return 'Voltar para o início'
            }

            return 'Ir para o login'
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-error.scss';
</style>
